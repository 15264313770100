import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import(/* webpackChunkName: "home" */ '../views/poster/Note-One')
}, {
  path: '/global',
  name: 'Global-Home',
  component: () => import(/* webpackChunkName: "home" */ '../views/poster/Global-Note-One')
}, {
  path: '/two',
  name: 'PosterTwo',
  component: () => import(/* webpackChunkName: "home" */ '../views/poster/Note-Two')
}, {
  path: '/activity',
  name: 'Activity',
  component: () => import(/* webpackChunkName: "home" */ '../views/activity/Gd-One'),
  meta: { title: '寻找最佳评鉴者' }
}, {
  path: '/qrcode',
  name: 'Qrcode',
  component: () => import(/* webpackChunkName: "home" */ '../views/qrcode/Index')
}, {
  path: '/article/barrelType',
  name: 'Barrel-Type',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Barrel-Type')
}, {
  path: '/article/barrelStrength',
  name: 'Barrel-Strength',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Barrel-Strength')
}, {
  path: '/article/wineLabel',
  name: 'Wine-Label',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Wine-Label')
}, {
  path: '/article/obAndIb',
  name: 'OB-IB',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/OB-IB')
}, {
  path: '/article/scottishRegion',
  name: 'scottish-region',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Scottish-Region')
}, {
  path: '/article/takeNotes',
  name: 'take-notes',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Take-Notes')
}, {
  path: '/article/productionProcess',
  name: 'production-process',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Production-Process')
}, {
  path: '/article/wineTypes',
  name: 'wine-types',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/Wine-Types')
}, {
  path: '/article/news',
  name: 'news',
  component: () => import(/* webpackChunkName: "home" */ '../views/article/News-Detail')
}, {
  path: '/coverimage/note-style1',
  name: 'note-style1',
  component: () => import(/* webpackChunkName: "home" */ '../views/coverimage/Note-Style')
}, {
  path: '/coverimage/global-note-style1',
  name: 'global-note-style1',
  component: () => import(/* webpackChunkName: "home" */ '../views/coverimage/Global-Note-Style')
}, {
  path: '/activity/answer',
  name: 'answer',
  component: () => import(/* webpackChunkName: "home" */ '../views/activity/Answer-Detail')
}, {
  path: '/activity/answer-rank',
  name: 'answer-rank',
  component: () => import(/* webpackChunkName: "home" */ '../views/activity/Answer-Rank'),
  meta: { title: '排行榜' }
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
